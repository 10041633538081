import { useGetOrganizationsQuery } from "@features/organizations/organizations-api";
import { Organization } from "@interfaces/index";
import React from "react";

const useOrganizationOptions = () => {
  const { data: organizations } = useGetOrganizationsQuery({});
  return React.useMemo(
    () =>
      organizations?.map((organization: Organization) => ({
        label: organization.name,
        value: organization.id,
      })) || [],
    [organizations],
  );
};
export { useOrganizationOptions };
