import { Box, CircularProgress } from "@mui/material";
import React from "react";

const FullwidthLoader = () => {
  return (
    <Box
      width="100%"
      height={"100%"}
      display="flex"
      alignItems={"center"}
      justifyContent="center"
    >
      <CircularProgress color="primary" />
    </Box>
  );
};

export { FullwidthLoader };
