import { AddOrganizationForm } from "@components/forms/add-organization-form";
import { ConfirmDeleteContent } from "@components/modals/confirm-delete-content";
import { FullwidthLoader } from "@components/UI/fullwidth-loader";
import { TableDrawer } from "@components/UI/table-drawer";
import { TableTopBar } from "@components/UI/table-top-bar";
import { TableWrapper } from "@components/UI/table-wrapper";
import {
  useCreateOrganizationMutation,
  useDeleteOrganizationMutation,
  useGetOrganizationsQuery,
} from "@features/organizations/organizations-api";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Dialog } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridRowParams } from "@mui/x-data-grid";
import { getOrganizationsColumns } from "@utils/constants/table";
import { PageContainer } from "components/UI/page-container";
import React from "react";
import { generatePath, useNavigate } from "react-router-dom";

import ROUTES from "../../routing/routes";
import { OrganizationsType } from "../../types";

const Organizations = () => {
  const navigate = useNavigate();

  const { data: organizations, isFetching: isLoading } =
    useGetOrganizationsQuery({});
  const [createOrganization, { isLoading: isCreating }] =
    useCreateOrganizationMutation();
  const [removeOrganization] = useDeleteOrganizationMutation();

  const [removeModalData, setRemoveModalData] =
    React.useState<OrganizationsType | null>(null);
  const [addOrganizationDrawerVisible, setAddOrganizationDrawerVisible] =
    React.useState(false);

  const onRowClick = (params: GridRowParams) => {
    if (params.row.id) {
      navigate(generatePath(ROUTES.ORGANIZATION, { id: params.row.id }));
    }
  };

  const handleDeleteClick = (params: GridRowParams) => {
    setRemoveModalData({ ...params.row });
  };

  const handleConfirmRemove = async () => {
    if (removeModalData) {
      try {
        await removeOrganization(removeModalData?.id);
        setRemoveModalData(null);
      } catch (error) {
        console.error("Error removing organization:", error);
        throw error;
      }
    }
  };

  const onSubmitAddForm = async (
    data: OrganizationsType,
    reset: () => void,
  ) => {
    try {
      const res = await createOrganization(data).unwrap();
      if (res.id) {
        reset();
      }
    } catch (error) {
      console.error("Error creating organization:", error);
      throw error;
    }
  };

  const organizationsColumns = React.useMemo(
    () =>
      getOrganizationsColumns((params) => [
        <GridActionsCellItem
          showInMenu={true}
          key={"delete"}
          icon={<EditIcon />}
          label="Edit"
          onClick={() => onRowClick(params)}
        />,
        <GridActionsCellItem
          showInMenu={true}
          key={"delete"}
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => handleDeleteClick(params)}
        />,
      ]),
    [],
  );

  return (
    <PageContainer>
      <Dialog open={Boolean(removeModalData?.id)}>
        <ConfirmDeleteContent
          title={`Delete ${removeModalData?.name}`}
          content={"Are you sure you want to delete this organization?"}
          onConfirm={handleConfirmRemove}
          onCancel={() => setRemoveModalData(null)}
        />
      </Dialog>
      <TableTopBar
        addButtonTitle={"Add new organization"}
        title={"Organizations"}
        onAddButtonClick={() => setAddOrganizationDrawerVisible(true)}
        disabled={isLoading}
      />
      <TableDrawer
        open={addOrganizationDrawerVisible}
        onClose={() => setAddOrganizationDrawerVisible(false)}
        title={"Organizations"}
      >
        <AddOrganizationForm
          onAddOrganization={onSubmitAddForm}
          isLoading={isCreating}
        />
      </TableDrawer>
      {isLoading && <FullwidthLoader />}
      {!isLoading && (
        <TableWrapper>
          <DataGrid
            onRowClick={onRowClick}
            rows={organizations || []}
            columns={organizationsColumns}
            hideFooterPagination={true}
            disableColumnMenu={true}
            disableMultipleRowSelection={true}
            disableRowSelectionOnClick={true}
            hideFooter={true}
          />
        </TableWrapper>
      )}
    </PageContainer>
  );
};

export { Organizations };
