import { api } from "../api";

export const userApi = api
  .enhanceEndpoints({
    addTagTypes: ["locations"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getAllLocations: build.query({
        query: () => ({
          url: "/locations",
          method: "GET",
        }),
        providesTags: ["locations"],
      }),
    }),
  });

export const { useGetAllLocationsQuery } = userApi;
