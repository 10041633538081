import { getModalConfig } from "@components/shared/organization-people-attach/components/modalConfig";
import { useOrganizationPeopleValues } from "@components/shared/organization-people-attach/hooks/useOrganizationPeopleValues";
import { AttachInput } from "@components/UI/attach-input";
import { ItemsList } from "@components/UI/items-list/items-list";
import { ModalHeader } from "@components/UI/modal-header";
import { useUpdateOrganizationPeopleMutation } from "@features/organizations/organizations-api";
import { IOrganizationPeopleAttachProps } from "@interfaces/index";
import { Dialog } from "@mui/material";
import React from "react";

import { usePeopleOptions } from "../../../hooks/usePeopleOptions";
import { DropdownAction } from "../../../types";
import styles from "./organization-people-attach.module.scss";

interface IModalProps {
  mode: "add" | "remove" | "confirm" | null;
  value: string | null | DropdownAction;
}

const OrganizationPeopleAttach: React.FC<IOrganizationPeopleAttachProps> = ({
  organizationId,
}) => {
  const peopleOptions = usePeopleOptions();
  const organizationPeopleValues = useOrganizationPeopleValues(organizationId);
  const [updateOrganizationPeople] = useUpdateOrganizationPeopleMutation();
  const [modal, setModal] = React.useState<IModalProps>({
    mode: null,
    value: null,
  });

  const handleClose = () => setModal({ mode: null, value: null });

  const handleUpdatePeople = (
    type: "add" | "remove",
    selectedPeople: DropdownAction,
  ) => {
    if (organizationId && selectedPeople.value?.length) {
      updateOrganizationPeople({
        id: organizationId,
        type,
        value: selectedPeople.value,
      });
    }
  };

  const modalConfig = getModalConfig({
    peopleOptions,
    organizationPeopleValues,
    onSubmit: (selectedPeople: DropdownAction) =>
      handleUpdatePeople("add", selectedPeople),
    onRemovePeopleFromOrganization: (selectedPeople: DropdownAction) =>
      handleUpdatePeople("remove", selectedPeople),
    modalValue: modal.value as DropdownAction,
    handleClose,
  });

  return (
    <div>
      {modal.mode && (
        <Dialog
          open={true}
          classes={{ paper: styles.wrapper }}
          onClose={handleClose}
        >
          <ModalHeader
            title={modalConfig[modal.mode].header}
            onClose={() => setModal({ mode: null, value: null })}
          />
          {modalConfig[modal.mode].content}
        </Dialog>
      )}
      <AttachInput
        label="People"
        onClick={() =>
          setModal({
            mode: "add",
            value: null,
          })
        }
      >
        <ItemsList
          items={organizationPeopleValues}
          onCrossClick={(value) =>
            setModal({
              mode: "remove",
              value,
            })
          }
        />
      </AttachInput>
    </div>
  );
};

export { OrganizationPeopleAttach };
