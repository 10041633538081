import { ChangeSourceTargetGroups } from "@components/shared/change-source-target-groups";
import { PeopleInteractionsAttach } from "@components/shared/people-interactions-attach";
import { PeopleOrganizationAttach } from "@components/shared/people-organization-attach/people-organization-attach";
import { useUpdatePersonTargetGroupsMutation } from "@features/people/people-api";
import { useGetTargetGroupByPersonIdQuery } from "@features/target-groups/target-groups-api";
import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";

import { LocationsType, PeoplesType } from "../../../types";
import styles from "./people-details-form.module.scss";

interface IPeopleDetailsFormProps {
  data: PeoplesType;
  onChange: (data: PeoplesType) => void;
  peopleId: string | null;
  locations: LocationsType[];
}

const PeopleDetailsForm: React.FC<IPeopleDetailsFormProps> = ({
  data,
  onChange,
  peopleId,
  locations,
}) => {
  const { control } = useForm({
    defaultValues: {
      location_id: null,
    },
  });

  const [innerData, setInnerData] = React.useState<PeoplesType>(data);
  const [selectedLocation, setSelectedLocation] =
    React.useState<LocationsType | null>(
      locations.find((loc) => loc.id === data.location_id) || null,
    );

  React.useEffect(() => {
    setInnerData(data);
    const location = locations.find((loc) => loc.id === data.location_id);
    setSelectedLocation(location || null);
  }, [data, locations]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInnerData({
      ...innerData,
      [event.target.name]: event.target.value,
    });
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    onChange({
      ...innerData,
      [name]: value,
    });
  };

  const handleLocationChange = (value: LocationsType | null) => {
    setSelectedLocation(value);
    onChange({
      ...innerData,
      location_id: value ? value.id : null,
    });
  };

  return (
    <Grid container>
      <Grid item xs={12} lg={6}>
        <Stack direction={"column"} spacing={2} className={styles.row}>
          <FormControl className={styles.fieldWrapper}>
            <TextField
              name={"first_name"}
              onChange={handleChange}
              onBlur={onBlur}
              InputLabelProps={{ shrink: true }}
              value={innerData?.first_name}
              fullWidth={true}
              id="first-name"
              label="First Name*"
              variant="standard"
            />
          </FormControl>
          <FormControl className={styles.fieldWrapper}>
            <TextField
              name={"last_name"}
              onChange={handleChange}
              onBlur={onBlur}
              InputLabelProps={{ shrink: true }}
              value={innerData?.last_name}
              fullWidth={true}
              id="last-name"
              label="Last Name*"
              variant="standard"
            />
          </FormControl>
          <FormControl className={styles.fieldWrapper}>
            <Controller
              control={control}
              name="location_id"
              render={({ field: { onChange } }) => (
                <Autocomplete
                  options={locations}
                  getOptionLabel={(option) => option.name}
                  onChange={(
                    event: React.SyntheticEvent,
                    newValue: LocationsType | null,
                  ) => {
                    onChange(newValue ? newValue.id : null);
                    handleLocationChange(newValue);
                  }}
                  value={selectedLocation}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label="Location"
                    />
                  )}
                />
              )}
            />
          </FormControl>
        </Stack>
        <Box className={styles.fieldWrapper}>
          <PeopleOrganizationAttach peopleId={peopleId} />
        </Box>
        <Box className={styles.fieldWrapper}>
          <ChangeSourceTargetGroups
            targetId={peopleId}
            useGetSourceTargetGroupsBySourceIdQuery={
              useGetTargetGroupByPersonIdQuery
            }
            useUpdateSourceTargetGroupsMutation={
              useUpdatePersonTargetGroupsMutation
            }
          />
        </Box>
        <Box className={styles.fieldWrapper}>
          <PeopleInteractionsAttach personId={peopleId} />
        </Box>
      </Grid>
    </Grid>
  );
};

export { PeopleDetailsForm };
