import { Box } from "@mui/material";
import React from "react";

import styles from "./page-container.module.scss";

const PageContainer = ({ children }: { children: React.ReactNode }) => {
  return <Box className={styles.wrapper}>{children}</Box>;
};

export { PageContainer };
