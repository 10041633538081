import { Box } from "@mui/material";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import routes from "routing/routes";

import styles from "./auth-layout.module.scss";

const AuthLayout = () => {
  const localStorageToken = localStorage.getItem("accessToken");

  if (localStorageToken) {
    return <Navigate to={routes.HOME} replace />;
  }

  return (
    <Box component="main" className={styles.wrapper}>
      <Outlet />
    </Box>
  );
};

export { AuthLayout };
