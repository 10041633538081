import { Box } from "@mui/material";
import React from "react";

import styles from "./table-wrapper.module.scss";

const TableWrapper: React.FC<ITableWrapperProps> = ({ children }) => {
  return <Box className={styles.wrapper}>{children}</Box>;
};
interface ITableWrapperProps {
  children: React.ReactNode;
}

export { TableWrapper };
