import { api } from "../api";

interface LoginResponse {
  access_token: string;
}

export const userApi = api
  .enhanceEndpoints({ addTagTypes: ["user"] })
  .injectEndpoints({
    endpoints: (build) => ({
      getCurrentUser: build.query({
        query: () => ({
          url: "/user",
          method: "GET",
        }),
      }),
      updateCurrentUser: build.mutation({
        query: (body) => ({
          url: "/signup",
          method: "PATCH",
          body,
        }),
      }),
      createUser: build.mutation({
        query: (body) => ({
          url: "/users",
          method: "POST",
          body,
        }),
      }),
      login: build.mutation<LoginResponse, { email: string; password: string }>(
        {
          query: (body) => ({
            url: "/login",
            method: "POST",
            body,
          }),
        },
      ),
      delete: build.mutation({
        query: (body) => ({
          url: "/delete",
          method: "DELETE",
          body,
        }),
      }),
    }),
  });

export const {
  useCreateUserMutation,
  useGetCurrentUserQuery,
  useLoginMutation,
  useUpdateCurrentUserMutation,
  useDeleteMutation,
} = userApi;
