import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

import styles from "./back-button.module.scss";

interface IBackButtonProps {
  title: string;
  to: string;
}

const BackButton: React.FC<IBackButtonProps> = ({ title, to }) => {
  return (
    <Link to={to}>
      <Button color={"primary"} className={styles.label}>
        <Stack direction={"row"} alignItems={"center"} gap={2}>
          <NavigateBeforeIcon className={styles.icon} />
          <Typography className={styles.label}>{title}</Typography>
        </Stack>
      </Button>
    </Link>
  );
};

export { BackButton };
