import { api } from "../api";
export const peopleApi = api
  .enhanceEndpoints({
    addTagTypes: [
      "organization_target_groups",
      "target_groups_by_initiatives",
      "people_target_groups",
    ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getOrganizationTargetGroupsByOrganizationId: build.query({
        query: (organizationId) => ({
          url: `/organizations/${organizationId}/target_groups`,
          method: "GET",
        }),
        providesTags: (result, error, id) => [
          { type: "organization_target_groups", id },
        ],
      }),
      getTargetGroupByInitiativeId: build.query({
        query: (initiativeId) => ({
          url: `/initiatives/${initiativeId}/target_groups`,
          method: "GET",
        }),
        providesTags: (result, error, id) => [
          { type: "target_groups_by_initiatives", id },
        ],
      }),
      getTargetGroupByPersonId: build.query({
        query: (personId) => ({
          url: `/people/${personId}/target_groups`,
          method: "GET",
        }),
        providesTags: (result, error, id) => [
          { type: "people_target_groups", id },
        ],
      }),
    }),
  });

export const {
  useGetOrganizationTargetGroupsByOrganizationIdQuery,
  useGetTargetGroupByInitiativeIdQuery,
  useGetTargetGroupByPersonIdQuery,
} = peopleApi;
