import { Box, Button, Paper, Stack } from "@mui/material";
import React from "react";

import styles from "./attach-input.module.scss";

interface IAttachInputProps {
  label?: string;
  onClick?: () => void;
  buttonText?: string;
  children?: React.ReactNode;
}

const AttachInput: React.FC<IAttachInputProps> = ({
  label,
  onClick,
  children,
  buttonText = "Attach",
}) => {
  return (
    <Paper className={styles.wrapper}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        className={styles.row}
      >
        <Box>{label}</Box>
        <Box>
          <Button variant={"rounded"} onClick={onClick}>
            {buttonText}
          </Button>
        </Box>
      </Stack>
      <Box className={styles.valuesWrapper}>{children}</Box>
    </Paper>
  );
};

export { AttachInput };
