import { ControlledAutocomplete } from "@components/UI/controlled-autocomplete/controlled-autocomplete";
import { Button, FormControl, Stack } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";

import {
  AddOrganizationToPeopleFormType,
  DropdownAction,
} from "../../../types";
import styles from "./add-organization-to-people-form.module.scss";

interface IAddOrganizationToPeopleProps {
  organizationOptions: DropdownAction[];
  onSubmit: (organization: DropdownAction) => void;
}
const AddOrganizationToPeopleForm: React.FC<IAddOrganizationToPeopleProps> = ({
  organizationOptions,
  onSubmit,
}) => {
  const { handleSubmit, control, watch } = useForm<{
    organization: {
      label: string;
      value: string | null;
    };
  }>({
    defaultValues: {
      organization: {
        label: "",
        value: null,
      },
    },
  });
  const onSubmitForm = (data: AddOrganizationToPeopleFormType) => {
    if (data.organization.value !== null) {
      onSubmit({
        label: data.organization.label,
        value: data.organization.value,
      });
    }
  };
  return (
    <form className={styles.wrapper} onSubmit={handleSubmit(onSubmitForm)}>
      <FormControl variant="standard" fullWidth={true}>
        <ControlledAutocomplete
          control={control}
          options={organizationOptions}
          name={"organization"}
          label={"Organization"}
        />
      </FormControl>
      <Stack
        direction={"row"}
        justifyContent={"right"}
        gap={1}
        className={styles.buttonsWrapper}
      >
        <Button
          variant="text"
          type="submit"
          disabled={!watch("organization")?.value}
        >
          Attach
        </Button>
      </Stack>
    </form>
  );
};
export { AddOrganizationToPeopleForm };
