import { api } from "../api";
export const peopleApi = api
  .enhanceEndpoints({
    addTagTypes: ["person_interactions", "person", "people"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getPersonInteractions: build.query({
        query: (id) => ({
          url: `/people/${id}/interactions`,
          method: "GET",
        }),
        providesTags: (result, error, id) => [
          { type: "person_interactions", id },
        ],
      }),
      createPersonInteraction: build.mutation({
        query: (data) => ({
          url: `/people/${data.person_id}/interactions`,
          method: "POST",
          body: data,
        }),
        invalidatesTags: (result, error, { person_id: id }) => [
          "people",
          { type: "person_interactions", id },
        ],
      }),
      removePersonInteraction: build.mutation({
        query: (interactionId) => ({
          url: `/interactions/${interactionId}`,
          method: "DELETE",
        }),
        invalidatesTags: (result, error, { person_id: id }) => [
          "people",
          { type: "person_interactions", id },
        ],
      }),
      updatePersonInteraction: build.mutation({
        query: (data) => ({
          url: `/interactions/${data.id}`,
          method: "PATCH",
          body: data,
        }),
        invalidatesTags: (result, error, { person_id: id }) => [
          "people",
          { type: "person_interactions", id },
        ],
      }),
    }),
  });

export const {
  useGetPersonInteractionsQuery,
  useCreatePersonInteractionMutation,
  useRemovePersonInteractionMutation,
  useUpdatePersonInteractionMutation,
} = peopleApi;
