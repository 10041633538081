import { AddTargetGroupToOrganizationForm } from "@components/forms/add-target-group-to-organization-form";
import { AttachInput } from "@components/UI/attach-input";
import { ItemsList } from "@components/UI/items-list/items-list";
import { ModalHeader } from "@components/UI/modal-header";
import { useGetInitiativesQuery } from "@features/initiatives/initiatives";
import { useUpdateOrganizationTargetGroupsMutation } from "@features/organizations/organizations-api";
import { useUpdatePersonTargetGroupsMutation } from "@features/people/people-api";
import { useGetOrganizationTargetGroupsByOrganizationIdQuery } from "@features/target-groups/target-groups-api";
import { Initiative, TargetGroup } from "@interfaces/index";
import { Box, Button, Dialog, DialogActions } from "@mui/material";
import React from "react";

import {
  AddTargetGroupToOrganizationFormType,
  DropdownAction,
} from "../../../types";
import styles from "./change-source-target-groups.module.scss";

interface IOrganizationsTargetGroupsProps {
  targetId: string | null;
  useGetSourceTargetGroupsBySourceIdQuery: typeof useGetOrganizationTargetGroupsByOrganizationIdQuery;
  useUpdateSourceTargetGroupsMutation:
    | typeof useUpdateOrganizationTargetGroupsMutation
    | typeof useUpdatePersonTargetGroupsMutation;
}

interface IModalProps {
  mode: "add" | "remove" | "confirm" | null;
  value: string | null | DropdownAction;
}

const ChangeSourceTargetGroups: React.FC<IOrganizationsTargetGroupsProps> = ({
  targetId,
  useGetSourceTargetGroupsBySourceIdQuery,
  useUpdateSourceTargetGroupsMutation,
}) => {
  const [modal, setModal] = React.useState<IModalProps>({
    mode: null,
    value: null,
  });

  const { data: targetGroups, isFetching: isFetchingTargetGroups } =
    useGetSourceTargetGroupsBySourceIdQuery(targetId ?? "", {
      skip: !targetId,
    });

  const { data: initiatives, isFetching: isFetchingInitiatives } =
    useGetInitiativesQuery(undefined, { skip: !targetId });

  const [updateOrganizationTargetGroup] = useUpdateSourceTargetGroupsMutation();

  const handleAttachTargetGroup = (
    data: AddTargetGroupToOrganizationFormType,
  ) => {
    if (targetId && data.target_group.value) {
      updateOrganizationTargetGroup({
        id: targetId,
        type: "add",
        value: data.target_group.value,
      });
    }
  };

  const handleUnAttachTargetGroup = async (data: DropdownAction) => {
    try {
      if (targetId) {
        await updateOrganizationTargetGroup({
          id: targetId,
          type: "remove",
          value: data.value,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const targetGroupsOptions = React.useMemo(() => {
    if (isFetchingInitiatives || isFetchingTargetGroups) return [];
    return (
      targetGroups?.map((tg: TargetGroup) => ({
        label: `${
          initiatives?.find(
            (initiative: Initiative) => initiative.id === tg.initiative_id,
          )?.name
        } / ${tg.name}`,
        value: tg.id,
      })) || []
    );
  }, [
    targetGroups,
    initiatives,
    isFetchingInitiatives,
    isFetchingTargetGroups,
  ]);

  const modalConfig = {
    add: {
      header: "Select a target group",
      content: (
        <AddTargetGroupToOrganizationForm onSubmit={handleAttachTargetGroup} />
      ),
    },
    remove: {
      header: `Unassign "${
        typeof modal?.value === "object" && modal?.value?.label
          ? modal.value.label
          : ""
      }" target group?`,
      content: (
        <>
          <Box sx={{ padding: "24px 16px" }}>
            Are you sure you want to remove this item?
          </Box>
          <DialogActions sx={{ padding: 0 }}>
            <Button onClick={() => setModal({ mode: null, value: null })}>
              No
            </Button>
            <Button
              autoFocus
              onClick={() => {
                if (modal.value) {
                  handleUnAttachTargetGroup(modal.value as DropdownAction);
                }
              }}
            >
              Unassign
            </Button>
          </DialogActions>
        </>
      ),
    },
    confirm: {
      header: "Select a target group",
      content: null,
    },
  };

  return (
    <>
      {modal.mode !== null && (
        <Dialog
          open={true}
          classes={{
            paper: styles.modalContent,
          }}
        >
          <ModalHeader
            title={modalConfig[modal.mode].header}
            onClose={() => setModal({ mode: null, value: null })}
          />
          {modalConfig[modal.mode].content}
        </Dialog>
      )}

      <AttachInput
        buttonText={"Assign"}
        label={"Target Groups"}
        onClick={() =>
          setModal({
            mode: "add",
            value: null,
          })
        }
      >
        <ItemsList
          items={targetGroupsOptions}
          onCrossClick={(value) =>
            setModal({
              mode: "remove",
              value,
            })
          }
        />
      </AttachInput>
    </>
  );
};

export { ChangeSourceTargetGroups };
