import { UpdatePeopleOrganizationTypeRequest } from "@interfaces/index";

import { api } from "../api";
export const peopleApi = api
  .enhanceEndpoints({
    addTagTypes: [
      "people",
      "person",
      "organization_people",
      "people_organization",
      "people_target_groups",
    ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      createPerson: build.mutation({
        query: (body) => ({
          url: "/people",
          method: "POST",
          body,
        }),
        invalidatesTags: [{ type: "people", id: "LIST" }],
      }),
      getPeople: build.query({
        query: () => ({
          url: "/people",
          method: "GET",
        }),
        providesTags: ["people"],
      }),
      getPersonById: build.query({
        query: (id) => ({
          url: `/people/${id}`,
          method: "GET",
        }),
        providesTags: (result, error, id) => [{ type: "person", id }],
      }),
      updatePerson: build.mutation({
        query: ({ id, ...data }) => ({
          url: `/people/${id}`,
          method: "PATCH",
          body: data,
        }),
        invalidatesTags: (result, error, { id }) => [
          "people",
          { type: "person", id },
        ],
      }),
      updateOrganizationsForPerson: build.mutation({
        query: ({ id, organizations }) => ({
          url: `/people/${id}/organizations`,
          method: "PATCH",
          body: { organizations },
        }),
        invalidatesTags: (result, error, { id }) => [{ type: "person", id }],
      }),
      deletePerson: build.mutation({
        query: (id) => ({
          url: `/people/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["person"],
      }),
      updatePeopleOrganization: build.mutation({
        query: ({ id, type, value }: UpdatePeopleOrganizationTypeRequest) => ({
          url: `/people/${id}/organizations`,
          method: "PATCH",
          body: {
            [type]: [value],
          },
        }),
        invalidatesTags: (result, error, { id }) => [
          "people",
          { type: "person", id },
          { type: "people_organization", id },
        ],
      }),
      getPeopleOrganizationByPeopleId: build.query({
        query: (id) => ({
          url: `/people/${id}/organizations`,
          method: "GET",
        }),
        providesTags: (result, error, id) => [
          { type: "people_organization", id },
        ],
      }),
      updatePersonTargetGroups: build.mutation({
        query: ({ id, type, value }) => ({
          url: `/people/${id}/target_groups`,
          method: "PATCH",
          body: {
            [type]: [value],
          },
        }),
        invalidatesTags: (result, error, { id }) => [
          "people",
          { type: "person", id },
          { type: "people_target_groups", id },
        ],
      }),
    }),
  });

export const {
  useCreatePersonMutation,
  useGetPeopleQuery,
  useGetPersonByIdQuery,
  useUpdatePersonMutation,
  useUpdateOrganizationsForPersonMutation,
  useDeletePersonMutation,
  useUpdatePeopleOrganizationMutation,
  useGetPeopleOrganizationByPeopleIdQuery,
  useUpdatePersonTargetGroupsMutation,
} = peopleApi;
