import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { Controller, FieldValues, UseControllerProps } from "react-hook-form";

import { DropdownAction } from "../../../types";

interface IControlledAutocompleteProps<T extends FieldValues>
  extends UseControllerProps<T> {
  disabled?: boolean;
  variant?: "standard" | "outlined" | "filled";
  options: DropdownAction[];
  label: string;
}

function ControlledAutocomplete<T extends FieldValues>({
  control,
  options,
  name,
  label,
  variant = "standard",
  disabled = false,
}: IControlledAutocompleteProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          disabled={disabled}
          freeSolo
          options={options}
          onChange={(event, values) => onChange(values)}
          value={value}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant={variant}
              onChange={onChange}
            />
          )}
        />
      )}
    />
  );
}

export { ControlledAutocomplete };
