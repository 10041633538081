import CloseIcon from "@mui/icons-material/Close";
import { Box, Drawer, IconButton, Stack, Typography } from "@mui/material";
import React from "react";

import styles from "./table-drawer.module.scss";

interface DrawerModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  children?: React.ReactNode;
}

const TableDrawer: React.FC<DrawerModalProps> = ({
  open,
  onClose,
  title,
  children,
}) => {
  return (
    <Drawer
      anchor={"right"}
      open={open}
      hideBackdrop={true}
      classes={{
        paper: styles.drawer,
        root: styles.drawerRoot,
      }}
    >
      <Box className={styles.drawerContainer}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant={"h3"}>{title}</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Box className={styles.contentWrapper}> {children}</Box>
      </Box>
    </Drawer>
  );
};

export { TableDrawer };
