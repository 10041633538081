import { Button, Stack, Typography } from "@mui/material";
import React from "react";

const TableTopBar: React.FC<ITableTopBarProps> = ({
  title = "Table Title",
  addButtonTitle = "Add",
  onAddButtonClick = () => {},
  disabled = false,
}) => {
  return (
    <Stack
      width={"100%"}
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Typography variant={"h3"}>{title}</Typography>
      <Button
        disabled={disabled}
        variant={"rounded"}
        onClick={onAddButtonClick}
      >
        {addButtonTitle}
      </Button>
    </Stack>
  );
};

interface ITableTopBarProps {
  title: string;
  addButtonTitle: string;
  onAddButtonClick?: () => void;
  disabled?: boolean;
}

export { TableTopBar };
