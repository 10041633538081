import { AuthLayout } from "@components/layouts/auth-layout";
import { DefaultLayout } from "@components/layouts/default-layout";
import { Login } from "@pages/login";
import { OrganizationDetails } from "@pages/organization-details";
import { Organizations } from "@pages/organizations";
import { People } from "@pages/people";
import { PeopleDetails } from "@pages/people-details";
import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from "react-router-dom";

import { ProtectedRoute } from "./protected-router";
import routes from "./routes";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={routes.HOME}>
        <Route
          element={
            <ProtectedRoute>
              <DefaultLayout />
            </ProtectedRoute>
          }
        >
          <Route index path={routes.HOME} element={<div>home</div>} />
          <Route index path={routes.DASHBOARD} element={<div>dashboard</div>} />
          <Route index path={routes.PEOPLES} element={<People />} />
          <Route index path={routes.PEOPLE} element={<PeopleDetails />} />
          <Route
            index
            path={routes.INITIATIVES}
            element={<div>initiatives</div>}
          />
          <Route
            index
            path={routes.ORGANIZATIONS}
            element={<Organizations />}
          />
          <Route
            index
            path={routes.ORGANIZATION}
            element={<OrganizationDetails />}
          />
          <Route index path={routes.EVENTS} element={<div>events</div>} />
          <Route
            index
            path={routes.COLLECTIONS}
            element={<div>collections</div>}
          />
        </Route>
      </Route>

      <Route element={<AuthLayout />}>
        <Route path={routes.LOGIN} element={<Login />} />
        <Route path={routes.SIGNUP} element={<div>sign</div>} />
      </Route>

      <Route path="*" element={<Navigate to={routes.HOME} replace />} />
    </>,
  ),
);

export default router;
