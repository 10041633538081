// modalConfig.tsx

import { AddOrganizationToPeopleForm } from "@components/forms/add-organization-to-people-form";
import { Box, Button, DialogActions } from "@mui/material";
import React from "react";

import { DropdownAction } from "../../../../types";

interface ModalConfigProps {
  organizationOptions: DropdownAction[];
  peopleOrganizationValues: DropdownAction[];
  onSubmit: (selectedOrganization: DropdownAction) => void;
  onRemovePeopleFromOrganization: (
    selectedOrganization: DropdownAction,
  ) => void;
  modalValue: DropdownAction | null;
  handleClose: () => void;
}

export const getModalConfig = ({
  organizationOptions,
  peopleOrganizationValues,
  onSubmit,
  onRemovePeopleFromOrganization,
  modalValue,
  handleClose,
}: ModalConfigProps) => ({
  add: {
    header: "Select a organization",
    content: (
      <AddOrganizationToPeopleForm
        organizationOptions={
          organizationOptions?.filter(
            (o: DropdownAction) =>
              !peopleOrganizationValues?.some(
                (po: DropdownAction) => po.value === o.value,
              ),
          ) || []
        }
        onSubmit={onSubmit}
      />
    ),
  },
  remove: {
    header: `Remove "${modalValue ? modalValue.label : ""}" from people?`,
    content: (
      <Box>
        <Box sx={{ padding: "24px 16px" }}>
          Are you sure you want to remove this item?
        </Box>
        <DialogActions sx={{ padding: 0 }}>
          <Button onClick={handleClose}>No</Button>
          <Button
            autoFocus
            onClick={() => {
              if (modalValue) {
                onRemovePeopleFromOrganization(modalValue);
                handleClose();
              }
            }}
          >
            Detach
          </Button>
        </DialogActions>
      </Box>
    ),
  },
  confirm: {
    header: "Confirm",
    content: "",
  },
});
