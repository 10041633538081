import { api } from "../api";
export const peopleApi = api
  .enhanceEndpoints({
    addTagTypes: ["initiatives"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getInitiatives: build.query({
        query: () => ({
          url: "/initiatives",
          method: "GET",
        }),
        providesTags: ["initiatives"],
      }),
    }),
  });

export const { useGetInitiativesQuery } = peopleApi;
