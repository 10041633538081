import { useGetPeopleOrganizationByPeopleIdQuery } from "@features/people/people-api";
import { Organization } from "@interfaces/index";
import React from "react";

const usePeopleOrganizationValues = (peopleId: string | null) => {
  const { data: peopleOrganization } =
    useGetPeopleOrganizationByPeopleIdQuery(peopleId);
  return React.useMemo(
    () =>
      peopleOrganization?.map((o: Organization) => ({
        label: o.name,
        value: o.id,
      })) || [],
    [peopleOrganization],
  );
};

export { usePeopleOrganizationValues };
