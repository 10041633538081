import { Button, Stack } from "@mui/material";
import { navLinksWithPath } from "@utils/constants/links";
import React from "react";
import { Link } from "react-router-dom";

import styles from "./app-navigation-header.module.scss";

const AppNavigationHeader: React.FC<IAppNavigationHeaderProps> = ({
  activeLink,
}) => {
  return (
    <Stack direction={"row"} justifyContent={"center"} gap={1}>
      {navLinksWithPath.map((page) => (
        <Link to={page.path} key={page.path}>
          <Button
            variant={activeLink === page.path ? "contained" : "text"}
            className={styles.navButton}
            color={"secondary"}
          >
            {page.name}
          </Button>
        </Link>
      ))}
    </Stack>
  );
};

interface IAppNavigationHeaderProps {
  activeLink: string;
}

export { AppNavigationHeader };
