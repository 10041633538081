import { ConfirmDeleteContent } from "@components/modals/confirm-delete-content";
import { AddPeopleForm } from "@components/shared/add-person-form";
import { FullwidthLoader } from "@components/UI/fullwidth-loader";
import { TableDrawer } from "@components/UI/table-drawer";
import { TableTopBar } from "@components/UI/table-top-bar";
import { TableWrapper } from "@components/UI/table-wrapper";
import {
  useCreatePersonMutation,
  useDeletePersonMutation,
  useGetPeopleQuery,
} from "@features/people/people-api";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Dialog } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridRowParams } from "@mui/x-data-grid";
import { getPeoplesColumns } from "@utils/constants/table";
import { PageContainer } from "components/UI/page-container";
import React from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { PeoplesType } from "types";

import ROUTES from "../../routing/routes";

type FormValues = {
  first_name: string;
  last_name: string;
};

const People = () => {
  const [addPeopleDrawerVisible, setAddPeopleDrawerVisible] =
    React.useState(false);
  const {
    data: peoples,
    error,
    isFetching: isLoading,
    refetch,
  } = useGetPeopleQuery({});
  const [createPerson, { isLoading: isCreating }] = useCreatePersonMutation();
  const [deletePerson] = useDeletePersonMutation();
  const [removeModalData, setRemoveModalData] =
    React.useState<PeoplesType | null>(null);
  const navigate = useNavigate();

  const onSubmitAddForm = async (data: FormValues) => {
    try {
      await createPerson(data).unwrap();
      refetch();
      setAddPeopleDrawerVisible(false);
    } catch (error) {
      console.error("Failed to create person:", error);
    }
  };

  const onRowClick = (params: GridRowParams) => {
    if (params.row.id) {
      navigate(generatePath(ROUTES.PEOPLE, { id: params.row.id }));
    }
  };

  const handleDeleteClick = (params: GridRowParams) => {
    setRemoveModalData({ ...params.row });
  };

  const handleConfirmRemove = async () => {
    if (removeModalData) {
      try {
        await deletePerson(removeModalData?.id);
        setRemoveModalData(null);
      } catch (error) {
        console.error("Error removing people:", error);
        throw error;
      }
    }
  };

  const peoplesColumns = React.useMemo(
    () =>
      getPeoplesColumns((params) => [
        <GridActionsCellItem
          showInMenu={true}
          key={"delete"}
          icon={<EditIcon />}
          label="Edit"
          onClick={() => onRowClick(params)}
        />,
        <GridActionsCellItem
          showInMenu={true}
          key={"delete"}
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => handleDeleteClick(params)}
        />,
      ]),
    [],
  );

  {
    isLoading && <FullwidthLoader />;
  }
  if (error) return <div>Failed to load data</div>;

  return (
    <PageContainer>
      <Dialog open={Boolean(removeModalData?.id)}>
        <ConfirmDeleteContent
          title={`Delete ${removeModalData?.first_name} ?`}
          content={"Are you sure you want to delete this item?"}
          onConfirm={handleConfirmRemove}
          onCancel={() => setRemoveModalData(null)}
        />
      </Dialog>
      <TableTopBar
        addButtonTitle={"Add new people"}
        title={"People"}
        onAddButtonClick={() => setAddPeopleDrawerVisible(true)}
        disabled={isLoading}
      />
      <TableDrawer
        open={addPeopleDrawerVisible}
        onClose={() => setAddPeopleDrawerVisible(false)}
        title={"Create a person"}
      >
        <AddPeopleForm onAddPeople={onSubmitAddForm} isLoading={isCreating} />
      </TableDrawer>
      {isLoading && <FullwidthLoader />}
      <TableWrapper>
        {peoples && (
          <DataGrid
            rows={peoples || []}
            columns={peoplesColumns}
            hideFooterPagination={true}
            disableColumnMenu={true}
            disableMultipleRowSelection={true}
            disableRowSelectionOnClick={true}
            hideFooter={true}
            getRowId={(row) => row.id}
            onRowClick={onRowClick}
          />
        )}
      </TableWrapper>
    </PageContainer>
  );
};

export { People };
