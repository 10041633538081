import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Stack } from "@mui/material";
import React from "react";

import { DropdownAction } from "../../../types";
import styles from "./items-list.module.scss";
interface IItemsListProps {
  items: DropdownAction[];
  onCrossClick?: (value: DropdownAction) => void;
}

const ItemsList: React.FC<IItemsListProps> = ({ items, onCrossClick }) => {
  return (
    <Stack>
      {items?.length > 0 &&
        items.map((val) => (
          <Box className={styles.wrapper} key={val.value}>
            <Box>{val.label}</Box>
            {onCrossClick && (
              <IconButton size={"small"} onClick={() => onCrossClick(val)}>
                <CloseIcon />
              </IconButton>
            )}
          </Box>
        ))}
    </Stack>
  );
};

export { ItemsList };
