// modalConfig.tsx

import { AddPersonToOrganizationForm } from "@components/forms/add-person-to-organization-form";
import { Box, Button, DialogActions } from "@mui/material";
import React from "react";

import { DropdownAction } from "../../../../types";

interface ModalConfigProps {
  peopleOptions: DropdownAction[];
  organizationPeopleValues: DropdownAction[];
  onSubmit: (selectedPeople: DropdownAction) => void;
  onRemovePeopleFromOrganization: (selectedPeople: DropdownAction) => void;
  modalValue: DropdownAction | null;
  handleClose: () => void;
}

export const getModalConfig = ({
  peopleOptions,
  organizationPeopleValues,
  onSubmit,
  onRemovePeopleFromOrganization,
  modalValue,
  handleClose,
}: ModalConfigProps) => ({
  add: {
    header: "Select a person",
    content: (
      <AddPersonToOrganizationForm
        peopleOptions={
          peopleOptions?.filter(
            (p: DropdownAction) =>
              !organizationPeopleValues?.some(
                (op: DropdownAction) => op.value === p.value,
              ),
          ) || []
        }
        onSubmit={onSubmit}
      />
    ),
  },
  remove: {
    header: `Remove "${modalValue ? modalValue.label : ""}" from organization?`,
    content: (
      <Box>
        <Box sx={{ padding: "24px 16px" }}>
          Are you sure you want to remove this item?
        </Box>
        <DialogActions sx={{ padding: 0 }}>
          <Button onClick={handleClose}>No</Button>
          <Button
            autoFocus
            onClick={() => {
              if (modalValue) {
                onRemovePeopleFromOrganization(modalValue);
                handleClose();
              }
            }}
          >
            Detach
          </Button>
        </DialogActions>
      </Box>
    ),
  },
  confirm: {
    header: "Confirm",
    content: "",
  },
});
