import AccountCircleSharpIcon from "@mui/icons-material/AccountCircleSharp";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import {
  profileActions,
  profileDropdownActions,
} from "@utils/constants/dropdowns";
import React from "react";
import { useNavigate } from "react-router-dom";
import routes from "routing/routes";

import { DropdownAction } from "../../../types";

const HeaderUserProfileDropdown = () => {
  const navigation = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogout = () => {
    localStorage.removeItem("accessToken");
    navigation(routes.LOGIN);
  };

  const actionsHandler = {
    [profileActions.profile.value]: () => console.log("profile"),
    [profileActions.settings.value]: () => console.log("settings"),
    [profileActions.logout.value]: () => onLogout(),
  };

  const onActionClick = (action: DropdownAction) => {
    if (!actionsHandler[action.value]) {
      console.error(`Unknown action: ${action.value}`);
      return;
    }
    actionsHandler[action.value]();
  };

  return (
    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <AccountCircleSharpIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {profileDropdownActions.map((action) => (
          <MenuItem key={action.value} onClick={() => onActionClick(action)}>
            {action.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export { HeaderUserProfileDropdown };
