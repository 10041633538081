import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { interactionActions } from "@utils/constants/dropdowns";
import dayjs from "dayjs";
import React from "react";

export const getOrganizationsColumns = (
  getActions: (params: GridRowParams) => React.ReactElement[],
): GridColDef[] => {
  return [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      headerClassName: "table-header",
    },
    {
      field: "website_url",
      headerName: "Website URL",
      flex: 1,
      headerClassName: "table-header",
    },
    {
      type: "actions",
      field: "actions",
      flex: 0,
      headerClassName: "table-header",
      getActions: getActions,
    },
  ];
};

export const getPeoplesColumns = (
  getActions: (params: GridRowParams) => React.ReactElement[],
): GridColDef[] => {
  return [
    {
      field: "first_name",
      headerName: "First Name",
      flex: 1,
      headerClassName: "table-header",
    },
    {
      field: "last_name",
      headerName: "Last Name",
      headerClassName: "table-header",
      flex: 1,
    },
    {
      field: "targetGroups",
      headerName: "Target groups",
      headerClassName: "table-header",
      flex: 1,
    },
    {
      field: "organizations",
      headerName: "Organizations",
      headerClassName: "table-header",
      flex: 1,
    },
    {
      type: "actions",
      field: "actions",
      flex: 0,
      headerClassName: "table-header",
      getActions: getActions,
    },
  ];
};

export const getInteractionsColumns = (
  getActions: (params: GridRowParams) => React.ReactElement[],
): GridColDef[] => {
  return [
    {
      field: "interaction_type",
      headerName: "Interaction Type",
      width: 200,
      headerClassName: "table-header",
      valueFormatter: (params) => {
        return interactionActions[params]?.label;
      },
    },
    {
      field: "date",
      headerName: "Date",
      width: 150,
      type: "date",
      valueFormatter: (params: string) =>
        dayjs(params).toDate().toLocaleDateString(),
      headerClassName: "table-header",
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      headerClassName: "table-header",
    },
    {
      type: "actions",
      field: "actions",
      flex: 0,
      headerClassName: "table-header",
      getActions: getActions,
    },
  ];
};
