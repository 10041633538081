import { OrganizationPeopleAttach } from "@components/shared/organization-people-attach";
import { useUpdateOrganizationTargetGroupsMutation } from "@features/organizations/organizations-api";
import { useGetOrganizationTargetGroupsByOrganizationIdQuery } from "@features/target-groups/target-groups-api";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { ChangeSourceTargetGroups } from "components/shared/change-source-target-groups";
import React from "react";

import { OrganizationsType } from "../../../types";
import styles from "./organization-details-form.module.scss";

interface IOrganizationDetailsFormProps {
  data: OrganizationsType;
  onChange: (data: OrganizationsType) => void;
  organizationId: string | null;
}

const OrganizationDetailsForm: React.FC<IOrganizationDetailsFormProps> = ({
  data,
  onChange,
  organizationId,
}) => {
  const [innerData, setInnerData] = React.useState<OrganizationsType>(data);
  React.useEffect(() => {
    setInnerData(data);
  }, [data]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInnerData({
      ...innerData,
      [event.target.name]: event.target.value,
    });
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    onChange({
      ...innerData,
      [name]: value,
    });
  };

  return (
    <Grid container>
      <Grid item xs={12} lg={6}>
        <Stack direction={"column"} spacing={2} className={styles.row}>
          <FormControl className={styles.fieldWrapper}>
            <TextField
              name={"name"}
              onChange={handleChange}
              onBlur={onBlur}
              InputLabelProps={{ shrink: true }}
              value={innerData?.name}
              fullWidth={true}
              id="name"
              label="Name*"
              variant="standard"
            />
          </FormControl>
          <FormControl className={styles.fieldWrapper}>
            <TextField
              name={"website_url"}
              onChange={handleChange}
              onBlur={onBlur}
              InputLabelProps={{ shrink: true }}
              value={innerData?.website_url}
              fullWidth={true}
              id="website_url"
              label="Website url"
              variant="standard"
            />
          </FormControl>

          <FormControl variant="standard" className={styles.fieldWrapper}>
            <InputLabel id="demo-simple-select-standard-label">
              Offices
            </InputLabel>
            <Select
              value={10}
              disabled={true}
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              label="Offices"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}></MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
          <Box className={styles.fieldWrapper}>
            <OrganizationPeopleAttach organizationId={organizationId} />
          </Box>
          <Box className={styles.fieldWrapper}>
            <ChangeSourceTargetGroups
              useUpdateSourceTargetGroupsMutation={
                useUpdateOrganizationTargetGroupsMutation
              }
              targetId={organizationId}
              useGetSourceTargetGroupsBySourceIdQuery={
                useGetOrganizationTargetGroupsByOrganizationIdQuery
              }
            />
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};

export { OrganizationDetailsForm };
