import { Button, CircularProgress, Stack, TextField } from "@mui/material";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { OrganizationsType } from "../../../types";
import styles from "./add-organization-form.module.scss";

const AddOrganizationForm: React.FC<IAddOrganizationFormProps> = ({
  onAddOrganization,
  isLoading,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<OrganizationsType>();

  const onSubmit: SubmitHandler<OrganizationsType> = async (data) => {
    const isErrorsEmpty = Object.keys(errors).length === 0;

    if (isErrorsEmpty) {
      onAddOrganization(data, reset);
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper}>
      <Stack gap={4}>
        <TextField
          disabled={isLoading}
          variant={"standard"}
          label="Name*"
          {...register("name", { required: "Name is required" })}
          error={!!errors.name}
          helperText={errors.name ? errors.name.message : ""}
        />
        <TextField
          disabled={isLoading}
          variant={"standard"}
          label="Website"
          {...register("website_url")}
          error={!!errors.website_url}
          helperText={errors.website_url ? errors.website_url.message : ""}
        />
      </Stack>
      <Stack direction={"row"} justifyContent={"flex-end"}>
        <Button disabled={isLoading} variant={"rounded"} type="submit">
          {isLoading && <CircularProgress size={22} color={"secondary"} />}
          {!isLoading && "Add"}
        </Button>
      </Stack>
    </form>
  );
};

interface IAddOrganizationFormProps {
  onAddOrganization: (
    data: OrganizationsType,
    reset: () => void,
  ) => Promise<void>;
  isLoading?: boolean;
}

export { AddOrganizationForm };
