import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

interface IConfirmDeleteContentProps {
  title: string;
  content: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmDeleteContent: React.FC<IConfirmDeleteContentProps> = ({
  title,
  content,
  onConfirm,
  onCancel,
}) => {
  return (
    <>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>No</Button>
        <Button autoFocus onClick={onConfirm}>
          Delete
        </Button>
      </DialogActions>
    </>
  );
};

export { ConfirmDeleteContent };
