import { useLoginMutation } from "@features/profile/userApi";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import React, { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [login, { isLoading, isError, error }] = useLoginMutation();

  const onLoginSuccess = () => {
    navigate("/");
  };

  const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await login({ email, password }).unwrap();
      localStorage.setItem("accessToken", response.access_token);
      onLoginSuccess();
    } catch (err) {
      console.error("Failed to log in:", err);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const isFetchBaseQueryError = (
    error: unknown,
  ): error is FetchBaseQueryError => {
    return typeof error === "object" && error !== null && "status" in error;
  };

  return (
    <>
      <Box
        component="form"
        onSubmit={handleLogin}
        sx={{ maxWidth: 400, mx: "auto", mt: 5, textAlign: "center" }}
      >
        <Typography variant="h3" mb={3}>
          Networking CRM
        </Typography>
        <Typography variant="h5" mb={3}>
          Sign In
        </Typography>
        <TextField
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          margin="normal"
          variant="filled"
          required
        />
        <TextField
          label="Password"
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          margin="normal"
          required
          variant="filled"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={24} /> : "Sign In"}
        </Button>
        {isError && isFetchBaseQueryError(error) && (
          <div>
            {error.data
              ? (error.data as { error: string }).error
              : "Unknown error"}
          </div>
        )}
      </Box>
    </>
  );
};

export { Login };
