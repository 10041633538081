import { UpdateOrganizationPeopleTypeRequest } from "@interfaces/index";

import { api } from "../api";

export const userApi = api
  .enhanceEndpoints({
    addTagTypes: [
      "organizations",
      "organization",
      "organization_people",
      "organization_target_groups",
    ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getOrganizations: build.query({
        query: () => ({
          url: "/organizations",
          method: "GET",
        }),
        providesTags: ["organizations"],
      }),
      createOrganization: build.mutation({
        query: (data) => ({
          url: "/organizations",
          method: "POST",
          body: data,
        }),
        invalidatesTags: ["organizations"],
      }),
      getOrganizationById: build.query({
        query: (id) => ({
          url: `/organizations/${id}`,
          method: "GET",
        }),
        providesTags: (result, error, id) => [{ type: "organization", id }],
      }),
      deleteOrganization: build.mutation({
        query: (id) => ({
          url: `/organizations/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["organizations"],
      }),
      updateOrganization: build.mutation({
        query: ({ id, ...data }) => ({
          url: `/organizations/${id}`,
          method: "PATCH",
          body: data,
        }),
        invalidatesTags: (result, error, { id }) => [
          "organizations",
          { type: "organization", id },
        ],
      }),
      updateOrganizationPeople: build.mutation({
        query: ({ id, type, value }: UpdateOrganizationPeopleTypeRequest) => ({
          url: `/organizations/${id}/people`,
          method: "PATCH",
          body: {
            [type]: [value],
          },
        }),
        invalidatesTags: (result, error, { id }) => [
          "organizations",
          { type: "organization", id },
          { type: "organization_people", id },
        ],
      }),
      getOrganizationsPeopleByOrganizationId: build.query({
        query: (id) => ({
          url: `/organizations/${id}/people`,
          method: "GET",
        }),
        providesTags: (result, error, id) => [
          { type: "organization_people", id },
        ],
      }),
      updateOrganizationTargetGroups: build.mutation({
        query: ({ id, type, value }) => ({
          url: `/organizations/${id}/target_groups`,
          method: "PATCH",
          body: {
            [type]: [value],
          },
        }),
        invalidatesTags: (result, error, { id }) => [
          "organizations",
          { type: "organization", id },
          { type: "organization_target_groups", id },
        ],
      }),
    }),
  });

export const {
  useGetOrganizationsQuery,
  useCreateOrganizationMutation,
  useGetOrganizationByIdQuery,
  useDeleteOrganizationMutation,
  useUpdateOrganizationMutation,
  useUpdateOrganizationPeopleMutation,
  useGetOrganizationsPeopleByOrganizationIdQuery,
  useUpdateOrganizationTargetGroupsMutation,
} = userApi;
