import { ControlledAutocomplete } from "@components/UI/controlled-autocomplete/controlled-autocomplete";
import { useGetInitiativesQuery } from "@features/initiatives/initiatives";
import { useGetTargetGroupByInitiativeIdQuery } from "@features/target-groups/target-groups-api";
import { Initiative, TargetGroup } from "@interfaces/index";
import { Button, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import { AddTargetGroupToOrganizationFormType } from "../../../types";
import styles from "./add-target-group-to-organization-form.module.scss";

interface IAddTargetGroupToOrganizationFormProps {
  onSubmit: (data: AddTargetGroupToOrganizationFormType) => void;
}

const AddTargetGroupToOrganizationForm: React.FC<
  IAddTargetGroupToOrganizationFormProps
> = ({ onSubmit }) => {
  const { handleSubmit, control, watch, resetField } =
    useForm<AddTargetGroupToOrganizationFormType>({
      defaultValues: {
        initiative: {
          label: "",
          value: null,
        },
        target_group: {
          label: "",
          value: null,
        },
      },
    });
  const { data: initiatives, isFetching: initiativesIsFetching } =
    useGetInitiativesQuery({});
  const { data: targetGroups, isFetching: targetGroupsIsFetching } =
    useGetTargetGroupByInitiativeIdQuery(watch("initiative")?.value, {
      skip: !watch("initiative")?.value,
    });

  useEffect(() => {
    resetField("target_group");
  }, [watch("initiative")?.value]);

  const initiativesOptions = initiatives?.map((initiative: Initiative) => ({
    label: initiative.name,
    value: initiative.id,
  }));

  const targetGroupsOptions = targetGroups?.map((tg: TargetGroup) => ({
    label: tg.name,
    value: tg.id,
  }));

  const onSubmitForm = (data: AddTargetGroupToOrganizationFormType) => {
    onSubmit(data);
  };

  return (
    <form className={styles.wrapper} onSubmit={handleSubmit(onSubmitForm)}>
      <Stack direction={"column"} gap={4}>
        <ControlledAutocomplete
          disabled={initiativesIsFetching}
          control={control}
          options={initiativesOptions}
          name={"initiative"}
          label={"Initiative"}
        />
        <ControlledAutocomplete
          disabled={targetGroupsIsFetching || !watch("initiative")?.value}
          control={control}
          options={targetGroupsOptions}
          name={"target_group"}
          label={"Target Group"}
        />
      </Stack>
      <Stack
        direction={"row"}
        justifyContent={"right"}
        gap={1}
        className={styles.buttonsWrapper}
      >
        <Button
          variant="text"
          type="submit"
          disabled={!watch("target_group")?.value}
        >
          Assign
        </Button>
      </Stack>
    </form>
  );
};

export { AddTargetGroupToOrganizationForm };
