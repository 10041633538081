import { DropdownAction } from "../../types";

export const profileActions = {
  profile: {
    label: "Profile",
    value: "profile",
  },
  settings: {
    label: "Settings",
    value: "settings",
  },
  logout: {
    label: "Logout",
    value: "logout",
  },
};

export const profileDropdownActions: DropdownAction[] = [
  {
    label: profileActions.profile.label,
    value: profileActions.profile.value,
  },
  {
    label: profileActions.settings.label,
    value: profileActions.settings.value,
  },
  {
    label: profileActions.logout.label,
    value: profileActions.logout.value,
  },
];

export const interactionActions: Record<string, DropdownAction> = {
  meeting: {
    label: "Meeting",
    value: "meeting",
  },
  video_call: {
    label: "Video call",
    value: "video_call",
  },
  event: {
    label: "Event",
    value: "event",
  },
};

export const interactionTypeDropdownActions: DropdownAction[] = [
  {
    label: interactionActions.meeting.label,
    value: interactionActions.meeting.value,
  },
  {
    label: interactionActions.video_call.label,
    value: interactionActions.video_call.value,
  },
  {
    label: interactionActions.event.label,
    value: interactionActions.event.value,
  },
];
