import { Button, CircularProgress, Stack, TextField } from "@mui/material";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import styles from "./add-person-form.module.scss";

type FormValues = {
  first_name: string;
  last_name: string;
  description?: string;
};
interface AddPeopleFormProps {
  isLoading?: boolean;
  onAddPeople: (data: FormValues) => Promise<void>;
}

const AddPeopleForm: React.FC<AddPeopleFormProps> = ({
  onAddPeople,
  isLoading,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>();

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const isErrorsEmpty = Object.keys(errors).length === 0;

    if (isErrorsEmpty) {
      await onAddPeople(data).then(() => {
        reset();
      });
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper}>
      <Stack gap={4}>
        <TextField
          disabled={isLoading}
          variant={"standard"}
          label="Name*"
          {...register("first_name", { required: "Name is required" })}
          error={!!errors.first_name}
          helperText={errors.first_name ? errors.first_name.message : ""}
        />
        <TextField
          disabled={isLoading}
          variant={"standard"}
          label="Last Name*"
          {...register("last_name")}
          error={!!errors.last_name}
          helperText={errors.last_name ? errors.last_name.message : ""}
        />
      </Stack>
      <Stack direction={"row"} justifyContent={"flex-end"}>
        <Button disabled={isLoading} variant={"rounded"} type="submit">
          {isLoading ? (
            <CircularProgress size={22} color={"secondary"} />
          ) : (
            "Add"
          )}
        </Button>
      </Stack>
    </form>
  );
};

export { AddPeopleForm };
