import { AttachInput } from "@components/UI/attach-input";
import { ItemsList } from "@components/UI/items-list/items-list";
import { useUpdatePeopleOrganizationMutation } from "@features/people/people-api";
import { IPeopleOrganizationAttachProps } from "@interfaces/index";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, IconButton, Stack, Typography } from "@mui/material";
import { useOrganizationOptions } from "hooks/useOrganizationOptions";
import React from "react";

import { DropdownAction } from "../../../types";
import { getModalConfig } from "./components/modalConfig";
import { usePeopleOrganizationValues } from "./hooks/usePeopleOrganizationValues";
import styles from "./people-organization-attach.module.scss";
interface IModalProps {
  mode: "add" | "remove" | "confirm" | null;
  value: string | null | DropdownAction;
}

const PeopleOrganizationAttach: React.FC<IPeopleOrganizationAttachProps> = ({
  peopleId,
}) => {
  const organizationOptions = useOrganizationOptions();
  const peopleOrganizationValues = usePeopleOrganizationValues(peopleId);
  const [updatePeopleOrganization] = useUpdatePeopleOrganizationMutation();
  const [modal, setModal] = React.useState<IModalProps>({
    mode: null,
    value: null,
  });

  const handleClose = () => setModal({ mode: null, value: null });

  const handleUpdateOrganization = (
    type: "add" | "remove",
    selectedOrganization: DropdownAction,
  ) => {
    if (peopleId && selectedOrganization.value?.length) {
      updatePeopleOrganization({
        id: peopleId,
        type,
        value: selectedOrganization.value,
      });
    }
  };

  const modalConfig = getModalConfig({
    organizationOptions,
    peopleOrganizationValues,
    onSubmit: (selectedOrganization: DropdownAction) =>
      handleUpdateOrganization("add", selectedOrganization),
    onRemovePeopleFromOrganization: (selectedOrganization: DropdownAction) =>
      handleUpdateOrganization("remove", selectedOrganization),
    modalValue: modal.value as DropdownAction,
    handleClose,
  });

  return (
    <div>
      {modal.mode && (
        <Dialog
          open={true}
          classes={{ paper: styles.wrapper }}
          onClose={handleClose}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h3">
              {modalConfig[modal.mode].header}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          {modalConfig[modal.mode].content}
        </Dialog>
      )}
      <AttachInput
        label="Organization"
        onClick={() =>
          setModal({
            mode: "add",
            value: null,
          })
        }
      >
        <ItemsList
          items={peopleOrganizationValues}
          onCrossClick={(value) =>
            setModal({
              mode: "remove",
              value,
            })
          }
        />
      </AttachInput>
    </div>
  );
};

export { PeopleOrganizationAttach };
