const ROUTES = {
  HOME: "/",
  LOGIN: "/login",
  SIGNUP: "/signup",
  DASHBOARD: "/dashboard",
  PEOPLES: "/people",
  PEOPLE: "/people/:id",
  INITIATIVES: "/initiatives",
  ORGANIZATIONS: "/organizations",
  ORGANIZATION: "/organizations/:id",
  EVENTS: "/events",
  COLLECTIONS: "/collections",
};

export default ROUTES;
