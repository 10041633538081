import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";

import routes from "../routes";

interface Props {
  children: ReactNode;
}

const ProtectedRoute: React.FC<Props> = ({ children }) => {
  const localStorageToken = localStorage.getItem("accessToken");

  if (!localStorageToken) {
    return <Navigate to={routes.LOGIN} replace />;
  }

  return <>{children}</>; // Use React Fragment to ensure a valid JSX element is returned
};

export { ProtectedRoute };
