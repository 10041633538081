import { PeopleDetailsForm } from "@components/forms/people-details-form";
import { BackButton } from "@components/UI/back-button";
import { FullwidthLoader } from "@components/UI/fullwidth-loader";
import { PageContainer } from "@components/UI/page-container";
import { useGetAllLocationsQuery } from "@features/locations/locationsApi";
import {
  useGetPersonByIdQuery,
  useUpdatePersonMutation,
} from "@features/people/people-api";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";

import routes from "../../routing/routes";
import styles from "./people-details.module.scss";

const PeopleDetails = () => {
  const params = useParams();
  const { data: people, isFetching } = useGetPersonByIdQuery(params.id);
  const { data: locations, isFetching: isFetchingLocations } =
    useGetAllLocationsQuery({});

  const [updatePerson] = useUpdatePersonMutation();

  if (isFetching || isFetchingLocations) return <FullwidthLoader />;
  return (
    <PageContainer>
      <Box className={styles.backWrapper}>
        <BackButton title={"Back to list"} to={routes.PEOPLES} />
      </Box>
      <Box className={styles.backWrapper}>
        <Typography variant={"h3"}>Person Detail</Typography>
      </Box>
      <Box>
        <PeopleDetailsForm
          data={people}
          onChange={updatePerson}
          peopleId={params.id || null}
          locations={locations}
        />
      </Box>
    </PageContainer>
  );
};

export { PeopleDetails };
