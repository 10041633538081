import routes from "../../routing/routes";

const navLinksWithPath = [
  {
    name: "Dashboard",
    path: routes.DASHBOARD,
  },
  {
    name: "People",
    path: routes.PEOPLES,
  },
  {
    name: "Initiatives",
    path: routes.INITIATIVES,
  },
  {
    name: "Organizations",
    path: routes.ORGANIZATIONS,
  },
  {
    name: "Events",
    path: routes.EVENTS,
  },
  {
    name: "Collections",
    path: routes.COLLECTIONS,
  },
];

export { navLinksWithPath };
