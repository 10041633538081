import { OrganizationDetailsForm } from "@components/forms/organization-details-form";
import { BackButton } from "@components/UI/back-button";
import { FullwidthLoader } from "@components/UI/fullwidth-loader";
import { PageContainer } from "@components/UI/page-container";
import {
  useGetOrganizationByIdQuery,
  useUpdateOrganizationMutation,
} from "@features/organizations/organizations-api";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";

import routes from "../../routing/routes";
import styles from "./organization-details.module.scss";

const OrganizationDetails = () => {
  const params = useParams();
  const { data: organization, isFetching } = useGetOrganizationByIdQuery(
    params.id,
  );
  const [updateOrganization] = useUpdateOrganizationMutation();

  if (isFetching) return <FullwidthLoader />;
  return (
    <PageContainer>
      <Box className={styles.backWrapper}>
        <BackButton title={"Back to list"} to={routes.ORGANIZATIONS} />
      </Box>
      <Box className={styles.backWrapper}>
        <Typography variant={"h3"}>Organization Details</Typography>
      </Box>
      <Box>
        <OrganizationDetailsForm
          data={organization}
          onChange={updateOrganization}
          organizationId={params.id || null}
        />
      </Box>
    </PageContainer>
  );
};

export { OrganizationDetails };
