import { AppNavigationHeader } from "@components/shared/app-navigation-header";
import { HeaderUserProfileDropdown } from "@components/shared/header-user-profile-dropdown";
import { AppBar, Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

import routes from "../../../routing/routes";
import styles from "./default-layout.module.scss";

const DefaultLayout = () => {
  const location = useLocation();
  return (
    <Stack className={styles.wrapper}>
      <AppBar position="static">
        <Grid container>
          <Grid item xs={1}>
            <Stack justifyContent={"center"} height={"100%"}>
              <Link to={routes.HOME} className={styles.logoTitle}>
                <Typography variant="h6">Networking CRM</Typography>
              </Link>
            </Stack>
          </Grid>
          <Grid item xs={10}>
            <AppNavigationHeader activeLink={location.pathname} />
          </Grid>
          <Grid item xs={1} display={"flex"} justifyContent={"flex-end"}>
            <HeaderUserProfileDropdown />
          </Grid>
        </Grid>
      </AppBar>
      <Box className={styles.contentWrapper}>
        <Outlet />
      </Box>
    </Stack>
  );
};

export { DefaultLayout };
