import { ControlledAutocomplete } from "@components/UI/controlled-autocomplete/controlled-autocomplete";
import { Button, Stack, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  interactionActions,
  interactionTypeDropdownActions,
} from "@utils/constants/dropdowns";
import dayjs from "dayjs";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { InteractionFormType, InteractionsType } from "../../../types";
import styles from "./add-interaction-form.module.scss";

interface IAddInteractionForm {
  onAddInteraction: (data: InteractionsType) => void;
  personData?: InteractionsType;
}

const AddInteractionForm: React.FC<IAddInteractionForm> = ({
  onAddInteraction,
  personData,
}) => {
  console.log(personData);
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { isValid },
  } = useForm<InteractionFormType>({
    defaultValues: {
      interaction_type:
        (personData?.interaction_type &&
          interactionActions[personData?.interaction_type]) ||
        interactionActions.meeting,
      description: personData?.description || "",
      date: personData?.date
        ? dayjs(personData?.date).format("YYYY-MM-DD")
        : dayjs(personData?.date).format("YYYY-MM-DD"),
    },
  });

  const onSubmit: SubmitHandler<InteractionFormType> = async (data) => {
    const res = {
      interaction_type: data.interaction_type.value || "",
      date: data.date,
      description: data.description,
    };
    onAddInteraction(res);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper}>
      <Stack direction={"column"} gap={4}>
        <ControlledAutocomplete
          options={interactionTypeDropdownActions}
          label={"Interactions type"}
          name={"interaction_type"}
          control={control}
        />
        <DatePicker
          value={dayjs(watch("date") || dayjs().format("YYYY-MM-DD"))} // Ensure dayjs receives a valid date
          onChange={(value) => {
            if (value) {
              setValue("date", value.format("YYYY-MM-DD")); // Use ISO string for consistency
            }
          }}
          slotProps={{ textField: { variant: "standard", fullWidth: true } }}
          label="Date"
        />
        <TextField
          variant={"standard"}
          fullWidth
          label={"Description"}
          {...register("description")}
        />
      </Stack>
      <Stack
        direction={"row"}
        justifyContent={"right"}
        gap={1}
        className={styles.buttonsWrapper}
      >
        <Button disabled={!isValid} variant="text" type="submit">
          Log
        </Button>
      </Stack>
    </form>
  );
};

export { AddInteractionForm };
