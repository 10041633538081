import { useGetOrganizationsPeopleByOrganizationIdQuery } from "@features/organizations/organizations-api";
import { Person } from "@interfaces/index";
import React from "react";

const useOrganizationPeopleValues = (organizationId: string | null) => {
  const { data: organizationPeople } =
    useGetOrganizationsPeopleByOrganizationIdQuery(organizationId);
  return React.useMemo(
    () =>
      organizationPeople?.map((p: Person) => ({
        label: p.first_name + " " + p.last_name,
        value: p.id,
      })) || [],
    [organizationPeople],
  );
};

export { useOrganizationPeopleValues };
