import { AddInteractionForm } from "@components/forms/add-interaction-form";
import styles from "@components/shared/change-source-target-groups/change-source-target-groups.module.scss";
import { AttachInput } from "@components/UI/attach-input";
import { ModalHeader } from "@components/UI/modal-header";
import {
  useCreatePersonInteractionMutation,
  useGetPersonInteractionsQuery,
  useRemovePersonInteractionMutation,
  useUpdatePersonInteractionMutation,
} from "@features/intercation/intercation-api";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Dialog, DialogActions } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { getInteractionsColumns } from "@utils/constants/table";
import React from "react";

import { InteractionsType } from "../../../types";

interface IModalProps {
  mode: "add" | "remove" | "edit" | null;
  value: string | null | InteractionsType;
}

interface IPeopleInteractionsAttachProps {
  personId: string | null | undefined;
}

// Type guard function
const isInteractionsType = (
  value: string | null | InteractionsType,
): value is InteractionsType => {
  return typeof value === "object" && value !== null && "id" in value;
};

const PeopleInteractionsAttach: React.FC<IPeopleInteractionsAttachProps> = ({
  personId,
}) => {
  const { data: interactions } = useGetPersonInteractionsQuery(personId, {
    skip: !personId,
  });

  const [createInteraction] = useCreatePersonInteractionMutation();
  const [removeInteraction] = useRemovePersonInteractionMutation();
  const [updateInteraction] = useUpdatePersonInteractionMutation();

  const [modal, setModal] = React.useState<IModalProps>({
    mode: null,
    value: null,
  });

  const onCreateInteraction = async (data: InteractionsType) => {
    await createInteraction({
      ...data,
      person_id: personId,
    }).unwrap();
    setModal({
      mode: null,
      value: null,
    });
  };

  const onUpdateInteraction = async (data: InteractionsType) => {
    if (isInteractionsType(modal.value)) {
      await updateInteraction({
        ...data,
        id: modal.value.id,
      }).unwrap();
      setModal({
        mode: null,
        value: null,
      });
    }
  };

  const modalConfig = {
    add: {
      header: "Log a new interaction",
      content: (
        <AddInteractionForm
          onAddInteraction={(data) =>
            personId && onCreateInteraction({ ...data, person_id: personId })
          }
        />
      ),
    },
    remove: {
      header: `Delete "${
        isInteractionsType(modal.value) ? modal.value.interaction_type : ""
      }" interaction?`,
      content: (
        <>
          <Box sx={{ padding: "24px 16px" }}>
            Are you sure you want to remove this interaction?
          </Box>
          <DialogActions sx={{ padding: 0 }}>
            <Button onClick={() => setModal({ mode: null, value: null })}>
              No
            </Button>
            <Button
              autoFocus
              onClick={() => {
                if (isInteractionsType(modal.value)) {
                  removeInteraction(modal.value.id)
                    .unwrap()
                    .then(() => {
                      setModal({ mode: null, value: null });
                    });
                }
              }}
            >
              Delete
            </Button>
          </DialogActions>
        </>
      ),
    },
    edit: {
      header: "Select a target group",
      content: (
        <AddInteractionForm
          personData={modal.value as InteractionsType}
          onAddInteraction={(data) => onUpdateInteraction(data)}
        />
      ),
    },
  };

  const interactionsColumns = React.useMemo(
    () =>
      getInteractionsColumns((params) => [
        <GridActionsCellItem
          showInMenu={true}
          key={"edit"}
          icon={<EditIcon />}
          label="Edit"
          onClick={() =>
            setModal({
              mode: "edit",
              value: params.row,
            })
          }
        />,
        <GridActionsCellItem
          showInMenu={true}
          key={"delete"}
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() =>
            setModal({
              mode: "remove",
              value: params.row,
            })
          }
        />,
      ]),
    [],
  );

  return (
    <>
      {modal.mode !== null && (
        <Dialog
          open={true}
          classes={{
            paper: styles.modalContent,
          }}
        >
          <ModalHeader
            title={modalConfig[modal.mode].header}
            onClose={() => setModal({ mode: null, value: null })}
          />
          {modalConfig[modal.mode].content}
        </Dialog>
      )}
      <AttachInput
        buttonText={"Log a new interaction"}
        onClick={() => setModal({ mode: "add", value: null })}
        label={"Interactions"}
      >
        <DataGrid
          pageSizeOptions={[5, 10, 25]}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 5, page: 0 },
            },
          }}
          disableColumnMenu={true}
          disableMultipleRowSelection={true}
          disableRowSelectionOnClick={true}
          columns={interactionsColumns}
          rows={interactions || []} // Ensure rows is not undefined
        />
      </AttachInput>
    </>
  );
};

export { PeopleInteractionsAttach };
