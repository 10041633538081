import { useGetPeopleQuery } from "@features/people/people-api";
import { Person } from "@interfaces/index";
import React from "react";

const usePeopleOptions = () => {
  const { data: people } = useGetPeopleQuery({});
  return React.useMemo(
    () =>
      people?.map((person: Person) => ({
        label: person.first_name + " " + person.last_name,
        value: person.id,
      })) || [],
    [people],
  );
};
export { usePeopleOptions };
