import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Stack, Typography } from "@mui/material";
import React from "react";

interface IModalConfig {
  title: string;
  onClose: () => void;
}

const ModalHeader: React.FC<IModalConfig> = ({ title, onClose }) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography variant="h3">{title}</Typography>
      <IconButton onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </Stack>
  );
};

export { ModalHeader };
