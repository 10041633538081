import { createTheme } from "@mui/material";
import { CSSProperties } from "react";

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: string;
    };
  }
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

declare module "@mui/material/AppBar" {
  interface AppBarPropsColorOverrides {
    background: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    rounded: true;
  }
}

declare module "@mui/material/TextField" {
  interface TextFieldPropsVariantOverrides {
    rounded: true;
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    h3Bold: CSSProperties;
  }

  interface TypographyVariantsOptions {
    h3Bold?: CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    h3Bold: true;
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: "#6750A4",
    },
    secondary: {
      main: "#F7F2FA",
    },
    background: {
      default: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
      fontSize: 16,
      letterSpacing: 0.1,
    },
    h3: {
      fontWeight: 500,
      fontSize: 22,
      letterSpacing: 0.1,
    },
    h3Bold: {
      fontWeight: 700,
      fontSize: 22,
      letterSpacing: 0.1,
    },
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
        color: "background",
      },
      styleOverrides: {
        root: {
          padding: "4px 24px",
          boxShadow: "0px 2px 16px 0px rgba(0, 0, 0, 0.15)",
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "rounded" },
          style: {
            borderRadius: 100,
            textTransform: "none",
            backgroundColor: "#6750A4", // primary.main
            color: "#fff",
            "&:hover": {
              backgroundColor: "#5b45a4", // darken primary.main for hover effect
            },
          },
        },
        {
          props: { variant: "text" },
          style: {
            borderRadius: 100,
          },
        },
      ],
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 28,
        },
      },
    },
  },
});
